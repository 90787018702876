import {
  AccountResponse,
  AddOnRequest,
  AddOnResponse,
  AddressFormData,
  AddressRequest,
  AddressResponse,
  BillingGroup,
  CCSurchargeResponse,
  ContactResponse,
  CountryType,
  EmailResponse,
  Industries,
  ManagementTypeResponse,
  MarketingGroup,
  Option,
  OrderDispositionResponse,
  PaymentMethod,
  PhoneFormData,
  PhoneRequest,
  PhoneResponse,
  SalesRepResponse,
  SelectOption,
  WebLinkResponse,
} from '@app/types'
import { User } from '@fsg/next-auth/types'

interface SiteBase {
  id: string
  name: string
  createdBy: User | null
  createdAt: Date
  updatedAt: Date
  accountStatus: string
  accountSell: boolean
  avgDaysToPay: number
  lastPaymentDate?: Date
  termsUpdatedAt?: Date
  creditAppStatus: string
  creditLimit: number
  creditUtilization: number
  netTerms: string
  holdPeriod: number
  serviceCharge: boolean
  creditCardSurchargeExemptionReason: string
  dateConstructed: string
  backordersAllowed: boolean
  substitutionsAllowed: boolean
  freightInCostRequired: boolean
  freightOutCostRequired: boolean
  customerPORequired: boolean
  lightingPO: string
  electricPO: string
  shippingProvider: string
  vendor: string
  taxID: string
  electricExempt: boolean
  lightingExempt: boolean
  taxExemptReason: string
  taxCertification: string
  taxJurisdiction: string
  taxRate: number
  taxElectricQuotes: boolean
  lightingContract: string
  electricServiceContract: string
  electricServiceException: string
  fuelChargeLighting: boolean
  fuelChargeElectric: boolean
  specialCharge?: string
  hourRoundFirstTwo: boolean
  hourRoundGT: boolean
  overtimeRate: number
  overtimeAllowed: boolean
  summaryBill: boolean
  billingCurrency: string
  electricsendInvoiceVia: string
  electricscheduleInvoice: string
  electricscopeNotes: string
  electricsystemTool: string
  electricbackupRequired: boolean
  electricbillingURL: string
  electricusername: string
  lightingSendInvoiceVia: string
  lightingscheduleInvoice: string
  lightingscopeNotes: string
  lightingsystemTool: string
  lightingbackupRequired: boolean
  lightingbillingURL: string
  lightingusername: string
  subCInventoryRequired: boolean
  billToMaster?: boolean
  formRequired: boolean
  addresses?: AddressResponse[]
  primaryAddress?: AddressResponse
  billingAddress?: AddressResponse
  emails?: EmailResponse[]
  phones?: PhoneResponse[]
  webLinks?: WebLinkResponse[]
  electricPaymentMethod?: PaymentMethod
  lightingPaymentMethod?: PaymentMethod
  billingGroup?: BillingGroup
  marketingGroup?: MarketingGroup
  accountManagementType?: ManagementTypeResponse
  statementPreference?: StatementPreference
  creditCardSurchargeType?: CCSurchargeResponse
  orderDisposition?: OrderDispositionResponse
  addOnTypes?: AddOnResponse[]
  apContact: ContactResponse
  contacts?: ContactResponse[]
  source?: Source
  industries?: Industries[]
  erpId: string
  customerCode: string
  shippingNote: string
  accountPortfolioManager?: ContactResponse
  businessDevelopmentManager?: ContactResponse
  insideSalesRep?: ContactResponse
  accountPortfolioManagerSR?: SalesRepResponse
  businessDevelopmentManagerSR?: SalesRepResponse
  insideSalesRepSR?: SalesRepResponse
  totalOpportunities?: number
  totalEstimatedRevenue?: number
  previousYearSalesToDate?: number
  currentYearSalesToDate?: number
  tradeName: string
  customerSiteId: string
  group: string
  transmissionRep: ContactResponse
  customerServiceRep: ContactResponse
  taxExemptLighting: string
  taxExemptElectric: string
  addOn: string
  overtime: boolean
  specialChargeValue: number
  status: SiteStatus
  siteType: SiteTypes
  sendInvoiceVia: string
  invoiceSchedule: string
  invoiceCopies: number
  printZeroBillLines: boolean
  backupRequired: boolean
  subCInvoiceRequired: boolean
  customerFSGVendorNumber: string
  electricalInvoicingDifferent: boolean
  freightTerms: string
  shipVia: string
  preferredTechnician: ContactResponse
}

export type SiteResponse = SiteBase & {
  owner: AccountResponse
  specialChargeAmount: string
  primaryPhone: PhoneResponse
}

export type SiteRequest = Omit<
  SiteBase,
  | 'id'
  | 'addOnTypes'
  | 'addOn'
  | 'owner'
  | 'apContact'
  | 'accountPortfolioManager'
  | 'businessDevelopmentManager'
  | 'insideSalesRep'
  | 'accountPortfolioManagerSR'
  | 'businessDevelopmentManagerSR'
  | 'insideSalesRepSR'
  | 'transmissionRep'
  | 'customerServiceRep'
  | 'preferredTechnician'
  | 'phones'
  | 'siteType'
  | 'contacts'
> & {
  ownerId: string
  addOnTypes?: AddOnRequest[]
  contactIds?: string[]
  apContactId?: string
  accountPortfolioManagerId?: string
  businessDevelopmentManagerId?: string
  insideSalesRepId?: string
  accountPortfolioManagerSRId?: string
  businessDevelopmentManagerSRId?: string
  insideSalesRepSRId?: string
  transmissionRepId?: string
  customerServiceRepId?: string
  preferredTechnicianId?: string
  phones: PhoneRequest[]
  siteType: string
  specialChargeAmount: number
}

export type SiteFormData = Omit<
  SiteBase,
  | 'holdPeriod'
  | 'addresses'
  | 'electricPaymentMethod'
  | 'lightingPaymentMethod'
  | 'orderDisposition'
  | 'addOnTypes'
  | 'status'
  | 'sendInvoiceVia'
  | 'invoiceSchedule'
  | 'netTerms'
  | 'freightTerms'
  | 'shipVia'
  | 'specialCharge'
  | 'creditCardSurchargeType'
  | 'accountPortfolioManager'
  | 'businessDevelopmentManager'
  | 'insideSalesRep'
  | 'accountPortfolioManagerSR'
  | 'businessDevelopmentManagerSR'
  | 'insideSalesRepSR'
  | 'transmissionRep'
  | 'customerServiceRep'
  | 'preferredTechnician'
  | 'apContact'
  | 'billingCurrency'
  | 'siteType'
  | 'contacts'
> & {
  owner: AccountResponse
  contacts: Option[]
  apContact: Option
  accountPortfolioManager: Option
  businessDevelopmentManager: Option
  insideSalesRep: Option
  accountPortfolioManagerSR: Option
  businessDevelopmentManagerSR: Option
  insideSalesRepSR: Option
  transmissionRep: Option
  customerServiceRep: Option
  preferredTechnician: Option
  holdPeriod: { label: string; value: number }
  addresses?: AddressFormData[]
  billingAddresses?: AddressFormData[]
  setPrimaryPhone: number
  setPrimarySiteAddress: number
  setPrimaryBillingAddress?: number
  electricPaymentMethod?: { label: string; value: PaymentMethod }
  lightingPaymentMethod?: { label: string; value: PaymentMethod }
  orderDisposition?: { label: string; value: OrderDispositionResponse }
  addOnTypes?: Option[]
  status: SelectOption<SiteStatus>
  netTerms: Option
  sendInvoiceVia: Option
  invoiceSchedule: Option
  freightTerms: Option
  shipVia: Option
  specialCharge: Option
  creditCardSurchargeType: boolean
  billingCurrency: Option
  siteType: Option
  specialChargeAmount: string
  primaryPhone: PhoneResponse
}

export type NewSiteRequest = {
  name: string
  phones: PhoneRequest[]
  addresses: AddressRequest[]
  siteType?: string
  ownerId: string
}

export type CredentialResponse = {
  billingURL: WebLinkResponse
  userName: string
  password: string
}

export type StatementPreference = {
  id: string
  value: string
}

export type Source = {
  id: string
  source: string
}

export type SitesResponse = {
  data: SiteResponse[]
  totalCount: number
  page: number
  limit: number
}

export type SiteTypes = {
  id: string
  type: string
  shortDescription: string
  longDescription: string
}

export enum SiteStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
