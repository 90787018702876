import {
  AccountRequest,
  AddressFormData,
  AddressRequest,
  AddressResponse,
  BusinessCard,
  CampaignResponse,
  ContactRequest,
  ContactResponse,
  EmailRequest,
  EmailResponse,
  Industries,
  JobFunction,
  MarketingSource,
  NewContactRequest,
  Option,
  PhoneFormData,
  PhoneRequest,
  PhoneResponse,
  SelectOption,
  TimeFrameResponse,
  WebLinkRequest,
  WebLinkResponse,
} from '@app/types'
import { User } from '@fsg/next-auth/types'

// REVIEW >> See contact.ts for refactor recommendations. #code-review--typescript
export type LeadQuality = {
  id: string
  rating: string
}

export type FSGServices = {
  id: string
  serviceType: string
}

export interface LeadResponse {
  id: string
  createdBy: User | null
  firstName: string
  lastName: string
  fullName: string
  companyName: string
  title?: string
  estimatedValue: string
  timeFrame: TimeFrameResponse
  salesType: string
  quality: LeadQuality
  primaryInterest?: string
  services: FSGServices[]
  industries?: Industries[]
  createdAt?: string
  updatedAt?: string
  addresses?: AddressResponse[]
  emails?: EmailResponse[]
  phones?: PhoneResponse[]
  webLinks?: WebLinkResponse[]
  status: LeadStatus
  source?: MarketingSource
  basicInformation: {
    cards: BusinessCard[]
  }
  jobFunctions: JobFunction[]
  campaign: CampaignResponse
  primaryRep: ContactResponse
  primaryEmail: EmailResponse
  primaryPhone: PhoneResponse
  primaryAddress: AddressResponse
  nextFollowUp: Date | string
  wcLeadId: string
  division: string
}
export type IndustriesRequest = {
  title?: string
  sequence?: number
  code?: number
}
export interface LeadRequest {
  firstName: string
  lastName: string
  fullName?: string
  companyName?: string
  title?: string
  estimatedValue?: number
  timeFrame?: TimeFrameResponse | string
  salesType?: string
  businessDevelopmentManager?: ContactResponse
  quality?: string
  primaryInterest?: string
  services?: string[]
  industries?: IndustriesRequest[]
  createdAt?: string
  updatedAt?: string
  addresses?: AddressRequest[]
  emails?: EmailRequest[]
  phones?: PhoneRequest[]
  webLinks?: WebLinkRequest[]
  status?: string
  source?: {
    source: string
  }
  basicInformation?: {
    cards: BusinessCard[]
  }
  jobFunctions?: JobFunction[]
  campaignId: string
  primaryRepId: string
  nextFollowUp?: Date | string
  division?: string
}

export type LeadFormData = {
  firstName: string
  lastName: string
  fullName?: string
  companyName?: string
  title?: string
  estimatedValue: string
  timeFrame?: Option
  salesType?: Option
  businessDevelopmentManager?: ContactResponse
  quality?: Option
  primaryInterest?: string
  services?: Option[]
  industries?: Option[]
  createdAt?: string
  updatedAt?: string
  setPrimaryPhone: number
  setPrimaryEmail: number
  setPrimaryAddress: number
  addresses?: AddressFormData[]
  emails?: EmailResponse[]
  phones?: PhoneFormData[]
  webLinks?: WebLinkRequest[]
  status?: Option
  source?: Option
  basicInformation?: {
    cards: BusinessCard[]
  }
  jobFunctions?: JobFunction[]
  campaignId: SelectOption
  primaryRep: Option
  nextFollowUp?: Date | string
  division?: Option
}

export type LeadStatus = {
  id: string
  lookup: string
  sort: number
  status: string
}

export type LeadConversionRequest = {
  contact: string | ContactRequest | NewContactRequest
  account: string | AccountRequest
  opportunity: {
    name: string
  }
}

export enum LeadStatusLookup {
  NEW = 'NEW',
  FIRST_48 = 'FIRST_48',
  CONTACTED = 'CONTACTED',
  QUALIFIED = 'QUALIFIED',
  NOT_QUALIFIED = 'NOT_QUALIFIED',
}
